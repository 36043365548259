<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ title }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  {{ title }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="First name"
                  placeholder="First name"
                  v-model="alumnus.first_name"
                  :error="$v.alumnus.first_name.$error"
                  outlined
                  dense
                >
                  <template v-slot:label>
                    First name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.alumnus.first_name.$error"
                  >This information is required.</span
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  placeholder="Middle name"
                  label="Middle name"
                  v-model="alumnus.middle_name"
                  outlined
                  dense
                >
                  <template v-slot:label> Middle name </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Last name"
                  placeholder="Last name"
                  v-model="alumnus.last_name"
                  outlined
                  :error="$v.alumnus.last_name.$error"
                  dense
                >
                  <template v-slot:label>
                    Last name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.alumnus.last_name.$error"
                  >This information is required.</span
                >
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  @blur="checkDuplicateEmail"
                  placeholder="Email"
                  v-model="alumnus.email"
                  :success="duplicateEmailStatus == 'SUCCESS'"
                  outlined
                  :error="
                    $v.alumnus.email.$error ||
                    !$v.alumnus.email.email ||
                    duplicateEmailStatus == 'ERROR'
                  "
                  dense
                >
                  <template v-slot:label>
                    Email <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="font-weight-medium text-danger" v-if="messages">{{
                  messages
                }}</span>
                <span class="text-danger" v-if="$v.alumnus.email.$error"
                  >This information is required</span
                >
                <span class="text-danger" v-if="!$v.alumnus.email.email"
                  >Email must be valid</span
                ><br />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field placeholder="Phone" v-model="alumnus.phone" outlined dense>
                  <template v-slot:label> Phone </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="!this.alumnusId">
                <v-text-field
                  dense
                  outlined
                  label="New password"
                  type="password"
                  v-model="alumnus.new_password"
                  :error="$v.alumnus.new_password.$error"
                >
                  <template v-slot:label>
                    New password <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.alumnus.new_password.$error"
                  >This information is required.</span
                >
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="!this.alumnusId">
                <v-text-field
                  dense
                  outlined
                  label="Confirm password"
                  :error="
                    $v.alumnus.confirm_password.$error ||
                    !$v.alumnus.confirm_password.sameAsPassword
                  "
                  type="password"
                  v-model="alumnus.confirm_password"
                >
                  <template v-slot:label>
                    Confirm password <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span
                  class="text-danger"
                  v-if="!$v.alumnus.confirm_password.sameAsPassword"
                  >Passwords must be identical</span
                ><br />
                <span class="text-danger" v-if="$v.alumnus.confirm_password.$error"
                  >This information is required.</span
                >
              </v-col>

              <v-col cols="12" sm="12" md="12" class="text-right">
                <!--                <v-btn
                    color="btn btn-standard"
                    dark
                    medium
                    @click="back">
                  Cancel
                </v-btn>-->
                <v-btn
                  v-if="checkIsAccessible('alumnus', 'create') || checkIsAccessible('alumnus', 'edit')"
                  class="btn btn-primary"
                  dark
                  @click="createOrUpdate()"
                  :loading="loading"
                  :disabled="buttonDisabled"
                >
                  {{ alumnusId ? "Update" : "Save" }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AlumnusService from "@/core/services/alumnus/AlumnusService";
import { required, sameAs, minLength, numeric, email } from "vuelidate/lib/validators";

const alumnus = new AlumnusService();
const academicYearService = new AcademicYearService();
const program = new ProgramService();

export default {
  validations() {
    return {
      alumnus: this.rules,
    };
  },
  data() {
    return {
      alumnus: {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        new_password: null,
        confirm_password: null,
        is_active: true,
        user_id: null,
        is_email_verified: true,
      },
      loading: false,
      programLoading: false,
      programs: [],
      academicYearLoading: false,
      academic_years: [],
      showPassword: false,
      messages: null,
      duplicateEmailStatus: null,
      buttonDisabled: false,
      alumnusDetail: false,
    };
  },
  mounted() {
    this.getAcademicYears();
    this.getAllPrograms();
    if (this.alumnusId) {
      this.findAlumnusDetail();
    }
  },
  methods: {
    getAllPrograms() {
      this.programLoading = true;
      program
        .all()
        .then((response) => {
          this.programs = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.programLoading = false;
        });
    },
    getAcademicYears() {
      this.academicYearLoading = true;
      academicYearService
        .all()
        .then((response) => {
          this.academic_years = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.academicYearLoading = false;
        });
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.alumnusId) {
          delete this.alumnus.new_password;
          delete this.alumnus.confirm_password;
          alumnus
            .update(this.alumnus.id, this.alumnus)
            .then((response) => {
              this.$snotify.success("Alumnus update successfully");
              this.findAlumnusDetail();
              this.back();
            })
            .catch((error) => {})
            .finally(() => {
              this.programLoading = false;
            });
        } else {
          alumnus
            .store(this.alumnus)
            .then((response) => {
              this.$snotify.success("Alumnus created successfully");
              this.back();
              this.resetData();
            })
            .catch((error) => {})
            .finally(() => {
              this.programLoading = false;
            });
        }
      }
    },
    resetData() {
      this.$v.$reset();
      this.alumnus = {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        new_password: null,
        confirm_password: null,
        is_active: true,
        user_id: null,
        is_email_verified: true,
      };
      this.messages = null;
      this.duplicateEmailStatus = null;
      this.buttonDisabled = false;
    },
    checkDuplicateEmail() {
      let data = { email: this.alumnus.email };
      if (this.alumnusId) {
        data.id = this.alumnusId;
      }
      alumnus
        .checkDuplicateEmail(data)
        .then((response) => {
          this.messages = response.data.message;
          this.duplicateEmailStatus = response.data.status;
          if (this.duplicateEmailStatus == "SUCCESS") {
            this.buttonDisabled = false;
          } else {
            this.buttonDisabled = true;
          }
        })
        .catch((error) => {
          this.errors = error.errors;
        });
    },
    findAlumnusDetail() {
      alumnus
        .show(this.alumnusId)
        .then((response) => {
          this.alumnus = response.data.alumnus;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    back() {
      this.$tabs.close().then(() => {
        this.$tabs.open({ name: "alumnus" });
      });
    },
  },
  computed: {
    rules() {
      let rule = {
        first_name: { required },
        last_name: { required },
        email: { required, email },
      };
      if (!this.alumnusId) {
        rule.new_password = { required };
        rule.confirm_password = {
          minLength: minLength(6),
          sameAsPassword: sameAs("new_password"),
          required,
        };
      }
      return rule;
    },
    alumnusId() {
      return this.$route.params.alumnusId;
    },
    title() {
      if (this.alumnusId) {
        return "Edit alumni";
      } else {
        return "Add alumni";
      }
    },
    routeTab() {
      if (this.alumnusId) {
        return {
          title: "Edit alumni",
        };
      } else {
        return {
          title: "Add alumni",
        };
      }
    },
  },
  watch: {},
};
</script>
